import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-support-browser',
  templateUrl: './no-support-browser.component.html',
  styleUrls: [
    './no-support-browser.component.css'
  ]
})
export class NoSupportBrowserComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
