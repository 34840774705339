// Still need to put prod variables
export const environment = {
  production: true,
  baseEndpointUrl: 'https://read-api.kacalp.com/read/v1',
  oktaIssuerURL: 'https://kaynecapital.okta.com/oauth2/ausm9vz9vuXCn8IVo0x7',
  oktaRedirectURL: 'https://read.kacalp.com/index.html',
  oktaClientId: '0oa1139g8f8XyoMWa0x8',
  oktaScopes: ['openid', 'email', 'profile'],
  syncfusionLicenseKey: 'Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW35ccHdQRmFcUEV0Wg==',
  messageTimeout: 10000,
  emailUs: 'asg@kaynecapital.com',
  emailUsCc: 'mfrench@kaynecapital.com;jnovelliere@kaynecapital.com;pmalmquist@kaynecapital.com;apassanante@kaynecapital.com',
  appTitle: 'READ',
  requestTimeoutInterval: 30,
  idleTimeInMinutes: 120,
  uploadRequestTimeout: 180000, // 3 minutes
  cachedHttpRequestTime: 100,
  cachedRawDataPnLTime: 30, // minutes
  mapbox: {
    accessToken: 'pk.eyJ1IjoidGh1YW5uaDEwMjciLCJhIjoiY2p4OXRseTZ5MHpveTN6dXRvY3FvbDhwYyJ9.xlEJC9_PscGQeYAKxA4tpA',
    markerUrl: 'https://read.kacalp.com/assets/img/single-point.png'
  },
  enableValuationApproval: true,
  enableCovenant: true
};
