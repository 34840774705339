import { KarEj2TreeGridComponent } from './kar-ej2-treegrid.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeGridModule, InfiniteScrollService, EditService, ResizeService } from '@syncfusion/ej2-angular-treegrid';
import { PageService, SortService, FilterService, ToolbarService, ExcelExportService } from '@syncfusion/ej2-angular-treegrid';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
    declarations: [
        KarEj2TreeGridComponent
    ],
    exports: [
        KarEj2TreeGridComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        // syncfusion
        TreeGridModule,
    ],
    providers: [
        // syncfusion
        PageService,
        SortService,
        FilterService,
        ToolbarService,
        ExcelExportService,
        InfiniteScrollService,
        EditService,
        ResizeService,
    ]
})
export class KarEj2TreeGridModule { }
