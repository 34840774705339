import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
    name: 'customPercent',
})
export class CustomPercentPipe implements PipeTransform {
    transform(
        value: number,
        digitsInfo: string = '1.0-2',
        locale: string = 'en-US',
        blank = '\u2014'
    ): string | null {
        if (!value || value === 0) { return blank; }

        const formattedCurrency = formatNumber(
            Math.abs(value),
            locale,
            digitsInfo,
        );


        if (value < 0) {
            if (formattedCurrency !== '0.00') {
                return `(${formattedCurrency}%)`;
            }
            return blank;
        }

        return `${formattedCurrency}%`;
    }


}
