import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TabCachedComponent } from './tab-cached/tab-cached.component';

export interface TabItem {
    name: string;
    active: boolean;
}

@Component({
    selector: 'app-tab-group-cached',
    templateUrl: './tab-group-cached.component.html',
    styleUrls: ['./tab-group-cached.component.css']
})
export class TabGroupCachedComponent implements AfterContentInit {
    @Input() loading = false;
    @Input() option = {
        right: false,
        theme: 'panel-primary',
        title: null,
        shouldChangeTab: null,
    };
    @Input() debounce: number = 700;
    @Input() inlineTabMode = false;
    @Input() allowChangeTab = true;
    @Output() tabChange = new EventEmitter();
    @Output() tabClick = new EventEmitter();

    @ContentChildren(TabCachedComponent) tabs: QueryList<TabCachedComponent>;

    timer;

    get themeClasses() {
        const classes = [];
        const arr = this.option && this.option.theme ? this.option.theme.split(' ') : [];

        arr.forEach(element => {
            classes.push(element);
        });

        return classes;
    }

    get ulClasses() {
        const classes = ['nav', 'nav-tabs'];
        classes.push(this.option && this.option.right ? 'reverse' : 'default');

        if (this.option) {
            if (this.option.title) {
                classes.push('has-title');
            }
        }

        return classes;
    }

    constructor() { }

    // contentChildren are set
    ngAfterContentInit() {
        // get all active tabs
        const activeTabs = this.tabs.filter((tab) => tab.active);

        // if there is no active tab set, activate the first
        if (activeTabs.length === 0) {
            this.selectTab(this.tabs.first);
        }
    }

    selectTab(tab: any) {
        if (!tab || !!tab.disabled) { return; }

        this.tabClick.emit(tab);

        if ((this.option.shouldChangeTab && this.option.shouldChangeTab()) || !this.option.shouldChangeTab) {
            return this.changeTab(tab);
        }

        return;
    }

    changeTab(tab: any) {
        // deactivate all tabs
        this.tabs.toArray().forEach(item => item.active = item.tabTitle === tab.tabTitle);

        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.timer = setTimeout(() => {
            this.tabs.toArray().forEach(item => item.ready = item.tabTitle === tab.tabTitle);
            this.tabChange.emit(tab);

            clearTimeout(this.timer);
            this.timer = null;
        }, this.debounce);
    }
}

export * from './tab-cached/tab-cached.component';
