import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(environment.syncfusionLicenseKey);
(window as any).endpointHost = environment.baseEndpointUrl.split('/').slice(0, 3).join('/');
(window as any).oktaHost = environment.oktaIssuerURL.split('/').slice(0 ,3).join('/')
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
