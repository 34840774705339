import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalCustomFilter } from 'src/app/models/common.model';

@Component({
  selector: 'app-main-filter-custom',
  templateUrl: './main-filter-custom.component.html',
  styleUrls: ['./main-filter-custom.component.css']
})
export class MainFilterCustomComponent implements OnInit, OnChanges, OnDestroy {
  selectedFullCustom: GlobalCustomFilter = { operator: null };

  cachedGeographies = [];

  isGeoOpened = false;

  geographies = [];
  @Input() source: string[];
  @Input() selectedThirdParty = [];

  @Output() add = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();

  @ViewChild('customDropdown') customDropdown: ElementRef;

  get isGeoValid() {
    return !this.selectedFullCustom.operator;
  }

  constructor(private renderer: Renderer2) {
    this.onRendererChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.source) {
      this.prepareData(this.source);
    }
  }

  ngOnInit() { }

  prepareData(source: string[] = []) {
    // Third Party
    this.geographies = source.map(el => {
      return {
        id: el,
        name: el,
        type: 'Select All',
      }
    })

    // cache
    this.cachedGeographies = [...this.geographies];
  }

  private onRendererChange() {
    this.renderer.listen('window', 'click', (e: any) => {
      const className = e.target && e.target.className && typeof e.target.className === 'string' ? e.target.className : '';
      if (!this.customDropdown.nativeElement.contains(e.target)
        && (e.target.className && className !== 'ng-star-inserted' && className.includes('ng-value-icon') === false
          && className.includes('ng-option') === false)) {
        this.isGeoOpened = false;
      }
    });
  }

  toggleGeo() {
    this.isGeoOpened = !this.isGeoOpened;
  }

  removeGeo(event, data) {
    event.stopPropagation();
    this.remove.emit(data);
  }

  onClearGeoDropdown() { }

  onSelectGeoDropdown() { }

  addGeo() {
    this.add.emit(this.selectedFullCustom.operator.map(el => el.name));
    this.resetCustomData();
    this.isGeoOpened = false;

  }

  resetCustomData() {
    this.geographies = this.cachedGeographies;
    this.selectedFullCustom = { operator: [] };
  }

  ngOnDestroy() { }

}
