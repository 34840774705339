import { GlobalFilter } from './../../models/common.model';
import { UtilityService } from 'ka-ui-lib';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DateService } from 'ka-ui-lib';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as utc from 'dayjs/plugin/utc';
import { ReadEndpointService } from 'src/app/services/read-endpoint.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { DEFAULT_FILTER_FUNDS, DEFAULT_FILTER_SECTORS, MainFilterService } from './services/main-filter.service';
import { MainFilterGeographyComponent } from './components/main-filter-geography/main-filter-geography.component';
import { GlobalFilterResponse } from './models/main-filter.model';
import { MainFilterCustomComponent } from './components/main-filter-custom/main-filter-custom.component';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-main-filter',
  templateUrl: './main-filter.component.html',
  styleUrls: [
    './main-filter.component.css'
  ]
})
export class MainFilterComponent implements OnInit, OnDestroy {

  isFilterReady = true;
  globalFilter: GlobalFilter = { funds: [], sectors: [], properties: [], geography: [], quarterEndDate: '', thirdPartyPropertyManagementCompanies: [] };

  allFunds = [];
  get funds() { return this.mainFilterService.funds; }
  orgFunds = [];
  sectors = [];
  allSectors = [];
  allOrgChartSectors = [];
  orgChartSectors = [];

  geographies = [];
  selectedGeographies = [];
  selectedThirdParty = [];
  mockStatus = [];
  selectedStatus;

  mockCustom = [];
  selectedCustom;

  properties = [];
  allProperties = [];
  allOrgChartProperties = [];
  selectedProperty;

  capitalInvestments = [];
  allCapitalInvestments = [];
  selectedCapitalInvestment;

  quarters = [];
  allQuarter = [];
  currentRouter = '';
  selectedQuarter;

  thirdParties = [];

  allUsGeographies: any[];
  orgTypes = [];
  allThirdParties: any[];

  listFilter: string[];
  hasFilterChanged = false;
  hasFilterClosed = false;
  hasFilterOpen = false;

  disabledQuarter = false;

  @ViewChild('geographySelect') geographySelect: MainFilterGeographyComponent;
  @ViewChild('customSelect') customSelect: MainFilterCustomComponent;

  get isOrgChartPage() {
    return this.location.path() === '/legal/org' || this.location.path() === '/legal/org-v2';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private utilityService: UtilityService,
    private mainFilterService: MainFilterService,
    private readEndpointService: ReadEndpointService,
    private dateService: DateService,
    private location: Location,
    private router: Router
  ) {
    this.onDetectRouterChange();
    this.readEndpointService.onGlobalFilterDataChangeReceived.subscribe(data => this.onGlobalFilterDataChangeReceived(data || {}));
    this.readEndpointService.onGlobalFilterOptionsChangeReceived.subscribe(data => this.onGlobalFilterOptionsChangeReceived(data || {}));
  }

  ngOnInit() {
    if (_.isEmpty(this.listFilter)) {
      this.initFilter(this.router.url);
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    if (this.hasFilterClosed) {
      this.onSelectGlobalFilterBlur();
      this.hasFilterClosed = false;
    }
  }

  private onDetectRouterChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initFilter(event.url);
      }
    });
  }

  private onGlobalFilterOptionsChangeReceived(
    { properties, funds }: { properties: string[], funds: string[], isUpdatePropertyOptions?: boolean }
  ) {
    if (!_.isEmpty(funds)) {
      this.globalFilter.funds = funds;
    }

    if (!_.isEmpty(properties)) {
      this.globalFilter.properties = properties;
    }

    this.globalFilter.isReset = false;

    this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
  }

  private onGlobalFilterDataChangeReceived({
    isResetDefaultOptions, isUpdatePropertyOptions, isResetPropertyOptions,
    properties
  }: {
    isResetDefaultOptions?: boolean, isUpdatePropertyOptions?: boolean, isResetPropertyOptions?: boolean,
    properties?: any[]
  }) {
    if (isResetDefaultOptions) {
      if (!_.isNil(properties)) {
        this.globalFilter.properties = properties;
      }
    }

    if (isUpdatePropertyOptions) {
      this.onUpdatePropertiesChange();
    }

    if (isResetPropertyOptions && this.allProperties.length > 0) {
      this.properties = [...this.allProperties];
    }
  }

  onUpdatePropertiesChange() {
    this.properties = _.filter(this.allProperties, (property) => {
      const isSector = this.globalFilter.sectors && this.globalFilter.sectors.length === 0 || _.includes(this.globalFilter.sectors, property.sector);
      const is3rdPartyProperty = this.globalFilter.thirdPartyPropertyManagementCompanies
        && this.globalFilter.thirdPartyPropertyManagementCompanies.length === 0 || _.includes(this.globalFilter.thirdPartyPropertyManagementCompanies, property.thirdPartyPropertyManagementCompanies);
      const isFund = this.globalFilter.funds && this.globalFilter.funds.length === 0 || (this.globalFilter.funds.findIndex(fund => property.fund.toLowerCase().includes(fund.toLowerCase())) > -1);

      const isGeography = this.globalFilter.geography && this.globalFilter.geography.length === 0 || _.some(this.globalFilter.geography, (geo) => {
        return (!geo.data.geography || geo.data.geography === property.usGeography)
          && (!geo.data.division || geo.data.division === property.usDivision)
          && (!geo.data.state || geo.data.state === property.usState)
          && (!geo.data.metroArea || geo.data.metroArea === property.usMetroArea);
      });
      if (isFund && isSector && isGeography && is3rdPartyProperty) {
        return property;
      }
    });
  }

  getFilterQuarter(currentPage: string): Observable<string[] | null> {
    this.onUpdatePropertiesChange();

    switch (currentPage) {
      case '/opportunistic':
        this.resetFilter();
        this.globalFilter.funds = DEFAULT_FILTER_FUNDS.OPPORTUNITY;
        return this.readEndpointService.getHomeQuarters();
      case '/core':
        this.resetFilter();
        this.globalFilter.funds = [...DEFAULT_FILTER_FUNDS.CORE];
        return this.readEndpointService.getHomeQuarters();
      case '/impact':
        this.resetFilter();
        this.globalFilter.funds = DEFAULT_FILTER_FUNDS.IMPACT;
        return this.readEndpointService.getHomeQuarters();
      case '/medical-office':
        this.resetFilter();
        this.globalFilter.sectors = DEFAULT_FILTER_SECTORS.MEDICAL_OFFICE;
        return this.readEndpointService.getHomeQuarters();
      case '/seniors-housing':
        this.resetFilter();
        this.globalFilter.sectors = [...DEFAULT_FILTER_SECTORS.SENIOR_HOUSING];
        return this.readEndpointService.getHomeQuarters();
      case '/student-multi':
        this.resetFilter();
        this.globalFilter.sectors = DEFAULT_FILTER_SECTORS.STUDENT_MULTI;
        return this.readEndpointService.getHomeQuarters();
      case '/legal/org':
      case '/legal/org-v2':
        if (!this.globalFilter.fundParent || this.globalFilter.fundParent === '') {
          this.globalFilter.fundParent = 'KAREP VI';
        }
        this.updateOrgChartFilterOptions();

        return this.readEndpointService.getHomeQuarters();
      case '/valuations':
        return this.readEndpointService.getValuationQuarters();
      case '':
      case '/':
      case '/home':
      case '/user-assignment':
      case '/financials':
      case '/covenants':
        return this.readEndpointService.getHomeQuarters();
      case '/covenant-watchlist':
      case '/watchlist':
      default:
        return of(null);
    }
  }

  bindQuarterToFilter(currentPage: string, quarters: string[]): void {
    // default action
    this.allQuarter = this.convertFilterValue(quarters);
    this.quarters = this.allQuarter;
    this.selectedQuarter = this.quarters.length > 0 ? this.quarters[0].id : null;

    switch (currentPage) {
      case '':
      case '/':
      case '/opportunistic':
      case '/core':
      case '/impact':
      case '/medical-office':
      case '/seniors-housing':
      case '/student-multi':
      case '/user-assignment':
      case '/covenants':
      case '/financials':
      case '/home':
        this.bindQuarterToHomeFilter();
        break;
      case '/valuations':
        this.bindQuarterToValuationFilter();
        break;
      case '/covenant-watchlist':
      case '/watchlist':
      case '/legal/org':
      case '/legal/org-v2':
      default:
        break;
    }
  }

  resetFilter() {
    this.selectedQuarter = this.quarters.length > 0 ? this.quarters[0].id : null;
    Object.keys(this.globalFilter).forEach(key => {
      if (key === 'quarterEndDate') {
        this.globalFilter.quarterEndDate = this.selectedQuarter;
      } else {
        this.globalFilter[key] = [];
      }
    });

    if (this.geographySelect) {
      this.geographySelect.resetGeoFilterData();
    }

    // if (this.customSelect) {
    //   this.customSelect.resetCustomData();
    // }

    this.selectedGeographies = [];
    this.selectedThirdParty = [];
    this.selectedCustom = null;
    this.selectedProperty = null;
    this.selectedStatus = null;
  }


  initFilter(url: string) {
    this.currentRouter = url;
    this.prepareFilterList();
    this.getReferenceData();
  }

  onResetFilter() {
    this.resetFilter();
    this.globalFilter.isReset = true;
    this.getReferenceData();
  }

  private convertFilterValue(input: string[]) {
    const allQuarters = [];
    input.forEach(f => {
      allQuarters.unshift({
        id: this.dateService.formatDate(f, 'YYYY-MM-DD'),
        name: `Q${dayjs(f).quarter()}-${dayjs(f).year()}`
      });
    });

    return allQuarters;
  }

  private bindQuarterToHomeFilter() {
    this.globalFilter.quarterEndDate = this.selectedQuarter;
  }

  private bindQuarterToValuationFilter() {
    this.globalFilter.quarterEndDate = this.selectedQuarter;
    this.globalFilter.comparisonDate = dayjs(this.selectedQuarter).date(1).subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
  }

  onSelectQuarter() {
    if (this.selectedQuarter) {
      this.globalFilter.quarterEndDate = this.selectedQuarter;
      this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
    }
  }

  onAddSector(e) {
    this.globalFilter.sectors = [...this.globalFilter.sectors, ...[e.id]];
  }

  onRemoveSector(e) {
    _.pull(this.globalFilter.sectors, e.value.id);
  }

  onAddFund(e) {
    this.globalFilter.funds = [...this.globalFilter.funds, ...[e.id]];
  }

  onRemoveFund(e) {
    _.pull(this.globalFilter.funds, e.value.id);
  }

  onSelectGlobalFilterOpen() {
    this.hasFilterOpen = true;
  }

  onSelectGlobalFilterClose() {
    this.hasFilterClosed = true;
    this.hasFilterOpen = false;
  }

  onSelectGlobalFilterBlur() {
    if (this.hasFilterChanged) {
      this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
      this.hasFilterChanged = false;
    }
  }

  onSelectGlobalFilterRemove() {
    if (!this.hasFilterOpen) {
      this.onSelectGlobalFilterBlur();
    }
  }

  onSelectGlobalFilterChange(e, type = '') {
    if (e.target) {
      return;
    }

    if (this.isOrgChartPage) {
      switch (type) {
        case 'sector':
          this.globalFilter.properties = [];
          this.globalFilter.capitalInvestments = [];
          break;
        case 'capitalInvestment':
          this.globalFilter.properties = [];
          break;
        case 'fundParent':
          this.globalFilter.properties = [];
          this.globalFilter.capitalInvestments = [];
          this.globalFilter.sectors = [];
          break;
        default:
          break;
      }

      this.updateOrgChartFilterOptions();
    } else {
      this.handlePropertyByFund();
    }
    this.hasFilterChanged = true;
  }

  handlePropertyByFund() {
    //Remove properties if it is not belong to the selected funds
    if (!_.isEmpty(this.globalFilter.properties)) {
      this.properties.forEach(property => {
        const isFund = this.globalFilter.funds && this.globalFilter.funds.length === 0 || (this.globalFilter.funds.findIndex(fund => property.fund.toLowerCase() === (fund.toLowerCase())) > -1);
        if (!isFund) {
          this.globalFilter.properties = this.globalFilter.properties.filter(prop => property.property.toLocaleLowerCase() !== prop.toLocaleLowerCase());
        }
      })
    }
  }

  private prepareFilterList() {
    const url = this.location.path();

    switch (url) {
      case '/legal/org':
      case '/legal/org-v2':
        this.listFilter = ['orgChartFund', 'orgChartSector', 'capitalInvestment', 'property', 'orgType'];
        break;
      default:
        this.listFilter = ['fund', 'sector', 'geography', 'property', 'status', 'custom', 'quarter'];
        break;
    }
  }

  prepareReferenceData(rs) {
    const { funds, sectors, properties, usGeographies, thirdPartyPropertyManagementCompanies } = rs;

    this.allUsGeographies = usGeographies;
    this.allThirdParties = thirdPartyPropertyManagementCompanies ? thirdPartyPropertyManagementCompanies.filter(el => !_.isNil(el)) : [];
    this.mainFilterService.funds = [...funds.map(f => ({
      id: f,
      name: f,
      type: 'Select All',
    }))].sort((a, b) => (a.name < b.name ? -1 : 1));

    this.sectors = [...sectors.map(f => ({
      id: f,
      name: f,
      type: 'Select All',
    }))];

    this.properties = [...properties.map(f => ({
      ...f,
      id: f.property,
      name: f.property,
      type: 'Select All',
    }))].sort((a, b) => (a.name < b.name ? -1 : 1));

    this.thirdParties = thirdPartyPropertyManagementCompanies ? [...thirdPartyPropertyManagementCompanies.map(f => ({
      id: f,
      name: f,
      type: 'Select All',
    }))] : [];

    this.allFunds = [...this.funds];
    this.allProperties = [...this.properties];
    this.allSectors = [...this.sectors];
    this.readEndpointService.globalPropertyFilterData = this.allProperties;
  }

  prepareOrgChartReferenceData(rs) {
    const { funds, properties, sectors, capitalInvestments } = rs as any;

    this.orgFunds = funds.map(e => ({
      id: e, name: e, type: 'SELECT ALL'
    }));

    this.allOrgChartProperties = properties.map(f => ({
      ...f,
      id: f.propertyName,
      name: f.propertyName,
      type: 'SELECT ALL',
    }));

    this.orgChartSectors = sectors.map(f => ({
      ...f,
      id: f.sector,
      name: f.sector,
      type: 'SELECT ALL',
    }));

    this.capitalInvestments = capitalInvestments.map(f => ({
      ...f,
      id: f.capitalInvestment,
      name: f.capitalInvestment,
      type: 'SELECT ALL',
    }));

    this.allCapitalInvestments = [...this.capitalInvestments];
    this.allOrgChartSectors = [...this.orgChartSectors];

    this.updateOrgChartFilterOptions();
  }

  getReferenceData() {
    // get quarter filter & reference data
    const referenceDataQueries: [Observable<string[]>, Observable<GlobalFilterResponse[]>, Observable<any[]>] = [
      this.getFilterQuarter(this.currentRouter),
      _.isEmpty(this.mainFilterService.funds) ? this.readEndpointService.getFilters() : of(null),
      this.isOrgChartPage ? this.readEndpointService.getOrgChartFilters() : of(null)
    ];

    this.isFilterReady = false;
    this.handleDisableQuarterFilter();
    forkJoin(referenceDataQueries).subscribe(([quarters, refData, orgRefData]) => {
      if (quarters) {
        this.bindQuarterToFilter(this.currentRouter, quarters);
      }

      if (refData) {
        this.prepareReferenceData(refData);
      }

      if (orgRefData) {
        this.prepareOrgChartReferenceData(orgRefData);
      }

      this.isFilterReady = true;
      this.readEndpointService.onGlobalFilterChanged.next({ ...this.globalFilter });
      this.readEndpointService.onGlobalFilterDataChanged.next({ quarters: this.quarters });

    }, () => {
      this.utilityService.toastError('Fail to get filter reference data');
    });
  }

  updateOrgChartFilterOptions() {
    // update the org chart filter options based on user selections
    const allProperties = JSON.parse(JSON.stringify(this.allOrgChartProperties));
    const allSectors = JSON.parse(JSON.stringify(this.allOrgChartSectors));
    const allCapitalInvestments = JSON.parse(JSON.stringify(this.allCapitalInvestments));

    const { fundParent, capitalInvestments, sectors } = this.globalFilter;

    this.properties = allProperties.filter(e => {
      return (!fundParent || e.funds.split(';').includes(fundParent)) &&
        (!capitalInvestments || capitalInvestments.length === 0 || capitalInvestments.includes(e.capitalInvestment)) &&
        (!sectors || sectors.length === 0 || sectors.includes(e.sector));
    });

    this.orgChartSectors = allSectors.filter(e => {
      return (!fundParent || e.funds.split(';').includes(fundParent));
    });

    this.capitalInvestments = allCapitalInvestments.filter(e => {
      return (!fundParent || e.funds.split(';').includes(fundParent)) &&
        (!sectors || this.globalFilter.sectors.length === 0 || sectors.includes(e.sector));
    });
  }

  onGeographyAdd(newGeo: any) {
    this.selectedGeographies = [...newGeo, ...this.selectedGeographies];
    this.globalFilter.geography = [...newGeo, ...this.globalFilter.geography];
    this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
  }

  onGeographyRemove(geo: any) {
    this.selectedGeographies = this.selectedGeographies.filter(item => item.id !== geo.id);
    this.globalFilter.geography = this.globalFilter.geography.filter(item => item.id !== geo.id);
    this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
  }

  onInput(target: any, event: Event) {
    target.filter((event.target as HTMLInputElement).value);
  }

  onCustomAdd(newCustom: any) {
    this.selectedThirdParty = [...newCustom, ...this.selectedThirdParty]
    this.globalFilter.thirdPartyPropertyManagementCompanies = [...newCustom, ...this.globalFilter.thirdPartyPropertyManagementCompanies];

    this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
  }

  onCustomRemove(custom: any) {
    this.selectedThirdParty = this.selectedThirdParty.filter(party => party !== custom);
    this.globalFilter.thirdPartyPropertyManagementCompanies = this.globalFilter.thirdPartyPropertyManagementCompanies.filter(party => party !== custom);

    this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
  }

  ngOnDestroy() {
    this.readEndpointService.onGlobalFilterDataChangeReceived = new BehaviorSubject(null);
  }

  private handleDisableQuarterFilter(): void {
    this.disabledQuarter = this.currentRouter === '/financials' || this.currentRouter === '/covenants';
  }
}
