import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KarAccount, MriAccount, PnLItem } from 'src/app/models/common.model';

@Injectable()
export class KarAccountService {

    private readAccount = 'readAccount';
    getUrl: 'readAccount';
    addUrl: '/readAccount/MRIAccounts';
    updateUrl: '/readAccount/MRIAccounts';

    constructor(private http: HttpClient) { }

    getKarAccounts() {
        return this.http.get<KarAccount[]>(`${environment.baseEndpointUrl}/${this.readAccount}`);
    }

    addKarAccounts(submitData: KarAccount[]) {
        return this.http.post<[KarAccount]>(`${environment.baseEndpointUrl}/${this.readAccount}/batchCreate`, submitData);
    }

    editKarAccounts(submitData: KarAccount[]) {
        return this.http.post<KarAccount>(`${environment.baseEndpointUrl}/${this.readAccount}/batchUpdate`, submitData);
    }

    getMRIAccountData() {
        return this.http.get<MriAccount[]>(`${environment.baseEndpointUrl}/${this.readAccount}/MRIAccounts`);
    }

    getPNLData() {
        return this.http.get<PnLItem[]>(`${environment.baseEndpointUrl}/${this.readAccount}/pnlItems`);
    }

    getDenominatorData() {
        return this.http.get<PnLItem[]>(`${environment.baseEndpointUrl}/${this.readAccount}/denominator`);
    }
}
