import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'currencySuff'
})
export class CurrencySuffixesPipe implements PipeTransform {

  // transform(input: any, args?: any, disableSuffixes?: boolean, disableCurrencySign?: boolean): any {
  //   let exp;
  //   const currencySign = disableCurrencySign ? '' : '$';
  //   const suffixes = disableSuffixes ? [''] : [' K', ' M', ' B', ' T', ' P', ' E'];
  //   const isNagtiveValues = input < 0;
  //   if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
  //     if (!!args && this.isNumeric(input) && !(input < 0) && input != 0) {
  //       return input.toFixed(args);
  //     } else {
  //       return input;
  //     }
  //   }


  //   if (!isNagtiveValues) {
  //     exp = Math.floor(Math.log(input) / Math.log(1000));

  //     return currencySign + (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  //   } else {
  //     input = input * -1;

  //     exp = Math.floor(Math.log(input) / Math.log(1000));

  //     return currencySign + (input * -1 / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  //   }
  // }

  isNumeric(value): boolean {
    if (value < 0) value = value * -1;
    if (/^-{0,1}\d+$/.test(value)) {
      return true;
    } else if (/^\d+\.\d+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  transform(value: any, args = '1.0-2', disableSuffixes?: boolean, disableCurrencySign?: boolean): any {
    if (value === null) return null;
    if (value === 0) return "0";
    const fractionSize = 1;
    let abs = Math.abs(value);
    const rounder = Math.pow(100, fractionSize);
    const isNegative = value < 0;
    let key = '';
    const powers = [{ key: " Q", value: Math.pow(10, 15) }, { key: " T", value: Math.pow(10, 12) }, { key: " B", value: Math.pow(10, 9) },
     { key: " M", value: Math.pow(10, 6) }, { key: " K", value: 1000 }];
    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;

      // reduced = (Math.round(reduced * rounder) / rounder);
      // if (Number.isNaN(value) || (value < 1000 && value >= 0) || !this.isNumeric(value) || (value < 0 && value > -1000)) {
      //   if (!!args && this.isNumeric(value) && !(value < 0) && value != 0) {
      //     return value.toFixed(args);
      //   } else {
      //     return value;
      //   }
      // }
      if (reduced >= 1) {
        abs = +reduced;
        key = powers[i].key;
        break;
      }
    }

    const outputNum = args === '0' ? abs.toFixed(0) : formatNumber(abs, 'en-US', args);
    const currencySign = disableCurrencySign ? '' : '$';
    const suffixes = disableSuffixes ? '' : key;
    if (isNegative) {
      return `(${currencySign}${outputNum}${suffixes})`;
    }
    return currencySign + (outputNum) + suffixes;
  }

}