import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
    name: 'customDecimal',
})
export class CustomDecimalPipe implements PipeTransform {
    transform(
        value: number,
        digitsInfo: string = '1.2',
        locale: string = 'en-US',
        blank = 'N/A'
    ): string | null {
        if (!value || value === 0) { return blank; }

        const formattedCurrency = formatNumber(
            Math.abs(value),
            locale,
            digitsInfo,
        );

        if (value < 0) {
            return `(${formattedCurrency})`;
        }

        return `${formattedCurrency}`;
    }
}
