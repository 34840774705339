import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReadEndpointService } from 'src/app/services/read-endpoint.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { UserProfileService } from 'ka-ui-lib';
import { GlobalFilter } from 'src/app/models/common.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-read-header',
  templateUrl: './read-header.component.html',
  styleUrls: [
    './read-header.component.css'
  ]
})
export class ReadHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() userLoginAs: string;
  @Input() version: string;

  @Output() collapsed = new EventEmitter<boolean>();
  @Output() settingOutput = new EventEmitter<{ type: string, isShow: boolean }>();
  globalFilter: GlobalFilter = {};
  isAbleToEdit = false;
  isFiltering = false;
  public isCollapsed = true;

  get enableCovenant() {
    return environment.enableCovenant;
  }

  constructor(
    private readEndpointService: ReadEndpointService,
    private userPermissionService: UserPermissionService
  ) {
    this.readEndpointService.onGlobalFilterChanged.subscribe((rs: GlobalFilter) => {
      this.globalFilter = rs;
    });
  }

  // get globalFilterObj() {
  //   return Object.keys(this.globalFilter)
  // }

  ngOnInit() { }

  // handleCollapseLeftBar(event: Event) {
  //   this.isCollapsed = !this.isCollapsed;
  //   this.collapsed.emit(this.isCollapsed);
  // }

  get isCheckAbleToEdit() {
    // this.userProfileService.getLoggedInUser().then(u => {
    //     return this.isAbleToEdit = u.userGroups.find(i => i === 'READ_EDIT') ? true : false;
    // }, (error) => {
    //     return this.isAbleToEdit = false;
    // });
    return this.userPermissionService.hasEditGroup();
  }

  onViewRow() {
    this.settingOutput.emit({ type: 'SETTING', isShow: true });
  }

  onViewKarAccount() {
    this.settingOutput.emit({ type: 'KAR_ACCOUNT', isShow: true });
  }

  onViewUserAssign() {
    this.settingOutput.emit({ type: 'USER_ASSIGNMENT', isShow: true });

  }
}
