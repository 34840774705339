import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations';

@Component({
  selector: 'app-read-left-navbar',
  templateUrl: './read-left-navbar.component.html',
  styleUrls: [
    './read-left-navbar.component.css'
  ],
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(250, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(250, style({ height: '*' }))
      ])
    ])
  ],
})
export class ReadLeftNavbarComponent implements OnInit {
  @Input() routes: any[];
  @Output() collapsed = new EventEmitter<boolean>();
  @Output() clearCache = new EventEmitter<boolean>();

  public isCollapsed = true;
  activeRoute = '/';
  activeChildRoute = '/';

  constructor(public router: Router) {
    this.isCollapsed = true;



    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.defineRoute(event.url);
        const listDropdown = document.body.querySelectorAll('.child-dropdown') as any;
        for (var i = 0; i < listDropdown.length; i++) {
          listDropdown[i].style.display = 'none'
        }
      }
    });
  }

  private defineRoute(url: string) {
    this.activeRoute = this.defineActiveRoute(url);
    this.activeChildRoute = url;
  }

  ngOnInit() {
    this.defineRoute(this.router.url);
  }

  handleCollapseLeftBar() {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }

  defineActiveRoute(url: string): string {
    if (!this.routes) { return ''; }

    let activeRoute = '/';
    this.routes.forEach(route => {
      if (!route.child && route.url === url) {
        activeRoute = route.url;
      } else if (route.child && route.child.some(childRoute => childRoute.url === url)) {
        activeRoute = route.url;
      }
    });

    return activeRoute;
  }

  onClearCache() {
    this.clearCache.emit(true);
  }

  onSubPageClick(e) {
    e.stopImmediatePropagation();
  }

  onMouseOver(evt) {
    if (evt.target.closest('.has-child') && evt.target.closest('.has-child').querySelector('ul')) {
      evt.target.closest('.has-child').querySelector('ul').style.display = 'block'
    }
    if (evt.target.closest('.li-hover') && evt.target.closest('.li-hover').querySelector('ul')) {
      evt.target.closest('.li-hover').querySelector('ul').style.display = 'block'
    }
  }
  onMouseOut(evt) {
    if (evt.target.closest('.has-child') && evt.target.closest('.has-child').querySelector('ul')) {
      evt.target.closest('.has-child').querySelector('ul').style.display = ''
    }
    if (evt.target.closest('.li-hover') && evt.target.closest('.li-hover').querySelector('ul')) {
      evt.target.closest('.li-hover').querySelector('ul').style.display = ''
    }
  }

}
