import { Component, OnChanges, Input } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Component({
    selector: 'app-svg-icon',
    template: `<svg-icon *ngIf="name" [name]="name"></svg-icon>`,
    styleUrls: [],
})
export class SvgLoaderComponent implements OnChanges {

    @Input()
    public name?: string;

    public svgIcon: any;

    constructor(private iconReg: SvgIconRegistryService) { }

    public ngOnChanges(): void {
        this.iconReg.loadSvg(`assets/img/${this.name}.svg`, this.name);
    }

}