import { GridOutputEventType, GridOutputEventArgs, CustomGridToolbar } from '../kar-ej2-treegrid/kar-ej2-treegrid.component';
import { ModalMode, CommonService } from 'ka-ui-lib';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'ka-ui-lib';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { KarTreeGridColumnOptions, KarTreeGridOptions, KarEj2TreeGridComponent } from '../kar-ej2-treegrid/kar-ej2-treegrid.component';
import { EditSettingsModel, ITreeData, ToolbarItems, TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { KarAccountService } from './services/kar-account.service';
import { KarAccount } from 'src/app/models/common.model';
import { DropDownList, ChangeEventArgs, DropDownListModel } from '@syncfusion/ej2-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { InputObject } from '@syncfusion/ej2-inputs';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { BatchChanges, DropDownEditCell, GridComponent } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

dayjs.extend(quarterOfYear);

declare var $: any;

@Component({
    selector: 'app-kar-account',
    templateUrl: './kar-account.component.html',
    styleUrls: [`./kar-account.component.css`]
})
export class KarAccountComponent implements OnInit {
    item: any;
    mode: ModalMode = ModalMode.ADD;
    isFormSubmitting = false;
    formErrors: string[] = [];
    quarters: any[] = [];
    modalClass: string = null;
    loading = false;
    gridData = [];
    mriAccount = [];
    pnlItems = [];
    denominatorItems = [];
    readCodeItems = [];
    accountObj: DropDownList;
    accountNumElm: HTMLElement;

    readCodeObj: DropDownList;
    readCodeElm: HTMLElement;

    pnlObj: DropDownList;
    pnlElm: HTMLElement;

    descriptionObj: InputObject;
    descriptionElm: HTMLElement;
    defaultHeight = (window.innerHeight - 300) / 2;

    gridOptions: KarTreeGridOptions;
    editSettings?: EditSettingsModel = { allowEditing: true, mode: 'Batch' };
    toolbarOptions?: ToolbarItems[] = ['Edit', 'Update', 'Cancel'];
    selectedAccountType;
    allReadAccount = [];
    data: DataManager;
    dropdownData = ['ALL', 'MATCHED', 'UNMATCHED'];
    dropdownCategoryData = [{ value: 'Expense' }, { value: 'Occupancy' }, { value: 'Revenue' }, { value: 'Exclusions' }];
    dropDown: DropDownList;
    isUnmatched = false;
    unmatchedBtbTitle = 'Show Unmatched only'
    currentSelectedRow;
    updateData = [];
    addData = [];
    isEnableUpdate = false;
    get readonly() {
        return this.mode === ModalMode.VIEW;
    }

    @ViewChild('mriGrid') mriGrid: KarEj2TreeGridComponent;
    @ViewChild('customFilter') customFilter: ElementRef;
    @ViewChild('customUpdateButton') customUpdateButton: ElementRef;

    constructor(
        private utilityService: UtilityService,
        private karAccountService: KarAccountService,
        private commonService: CommonService,
        private activeModal: NgbActiveModal,
    ) {
        this.selectedAccountType = this.dropdownData[0];
    }

    ngOnInit() {
        this.initReferenceData();
    }

    close() {
        this.activeModal.close();
    }

    setupGrid() {
        const defaultColumn: Partial<KarTreeGridColumnOptions> = {
            textAlign: 'Left',
            allowFiltering: true
        };

        const columns: KarTreeGridColumnOptions[] = [
            ...[
                {
                    headerText: 'Account No.',
                    field: 'accountNumber',
                    isFrozen: true,
                    isPrimaryKey: true,
                    allowEditing: false,
                    minWidth: 150,
                    width: 150
                },
                {
                    headerText: 'Description',
                    field: 'accountDescription',
                    validationRules: { required: true },
                    allowEditing: true,
                    minWidth: 180,
                    width: 180
                },
                {
                    minWidth: 100,
                    field: 'kayneAccountDenominator',
                    editType: 'dropdownedit',
                    allowEditing: true,
                    headerText: 'Kayne Acct. Denominator',
                    edit: {
                        params: {
                            dataSource: new DataManager(this.denominatorItems),
                            fields: { value: 'denominator', text: 'denominator' },
                            query: new Query(),
                            actionComplete: () => false,
                            floatLabelType: 'Never'
                        }
                    },
                },
                {
                    headerText: 'Category',
                    field: 'category',
                    validationRules: { required: true },
                    editType: 'dropdownedit',
                    allowEditing: true,
                    edit: {
                        params: {
                            dataSource: new DataManager(this.dropdownCategoryData),
                            fields: { value: 'value', text: 'value' },
                            query: new Query(),
                            actionComplete: () => false,
                            floatLabelType: 'Never'
                        }
                    },
                    minWidth: 120,
                    width: 120
                },
                {
                    headerText: 'P&L Group',
                    field: 'pnlGroup',
                    allowEditing: true,
                    minWidth: 120,
                    width: 120
                },
                {
                    headerText: 'P&L Code',
                    field: 'pnlCode',
                    allowEditing: false,
                    isHidden: true,
                    minWidth: 120,
                    width: 120
                },
                {
                    headerText: 'READ Code',
                    field: 'code',
                    validationRules: { required: true },
                    editType: 'dropdownedit',
                    allowEditing: true,
                    edit: {
                        params: {
                            dataSource: new DataManager(this.readCodeItems),
                            fields: { value: 'readCode', text: 'readCode' },
                            query: new Query(),
                            actionComplete: () => false,
                            change: this.onReadCodeChange.bind(this),
                            floatLabelType: 'Never'
                        }
                    },
                    minWidth: 120,
                    width: 120
                },
                {
                    minWidth: 100,
                    field: 'readAccount',
                    editType: 'defaultedit',
                    allowEditing: false,
                    headerText: 'READ Account',
                },
                {
                    minWidth: 100,
                    field: 'readAccountDenominator',
                    editType: 'dropdownedit',
                    allowEditing: true,
                    headerText: 'READ Acct. Denominator',
                    edit: {
                        params: {
                            dataSource: new DataManager(this.denominatorItems),
                            fields: { value: 'denominator', text: 'denominator' },
                            query: new Query(),
                            actionComplete: () => false,
                            change: this.onReadAcctDenominatorChange.bind(this),
                            floatLabelType: 'Never'
                        }
                    },
                },
            ]
        ].map(c => ({ ...defaultColumn, ...c }));


        this.gridOptions = {
            height: '40vh',
            width: '100%',
            filterSettings: { type: 'Excel' },
            columns,
            allowExcelExport: true,
            rowHeight: 34,
            excelExportProperties: {
                includeHiddenColumn: true,
                fileName: `READ_ACCOUNT_${dayjs().format('MMDDYYYY')}.xlsx`,
                enableFilter: true
            },
            rowSelected: (params) => this.onRowSelect(params),
            selectionOptions: { type: 'Single' },
            editSettings: this.editSettings,
            toolbarOptions: [
                'Edit',
                {
                    id: CustomGridToolbar.CUSTOM_UPDATE_BTN,
                    template: this.customUpdateButton
                },
                'Cancel',
                {
                    id: CustomGridToolbar.CUSTOM_FITLER,
                    align: 'Right',
                    template: this.customFilter
                },
                {
                    id: CustomGridToolbar.EXCEL_EXPORT,
                    text: 'Export',
                    prefixIcon: 'e-export-excel',
                    align: 'Right'
                }
            ],
            enableInfiniteScrolling: false
        };
    }

    onRowSelect(params) {
        if (params) {
            this.currentSelectedRow = params;
        }

    }

    onCellSaved(args) {
        // const mriTreeGrid = this.mriGrid.grid as TreeGridComponent;
        // const changedRecords = (mriTreeGrid.getBatchChanges() as BatchChanges);
        // this.dataChanged = changedRecords && changedRecords.changedRecords ? changedRecords.changedRecords : [];
    }

    onClickUpdate(args) {
        const mriTreeGrid = this.mriGrid.grid as TreeGridComponent;
        if (mriTreeGrid) {
            mriTreeGrid.endEdit();
            const changedRecords = (mriTreeGrid.getBatchChanges() as BatchChanges).changedRecords;
            this.updateData = [];
            this.addData = [];
            changedRecords.map((data: any) => {
                const submitData: KarAccount = {
                    accountNumber: data.accountNumber,
                    code: data.code,
                    kayneAccountDenominator: data.kayneAccountDenominator,
                    readAccountDenominator: data.readAccountDenominator,
                    accountDescription: data.accountDescription,
                    readAccount: data.readAccount,
                    pnlGroup: data.pnlGroup,
                    category: data.category,
                    id: data.id,
                };
                data.assignedAccount ? this.updateData.push(submitData) : this.addData.push(submitData);
            });
            // if (this.updateData.length > 0 || this.addData.length > 0) {
            //     mriTreeGrid.endEdit();
            // }
        }
    }

    onReadCodeChange(arg) {
        const mriTreeGrid = this.mriGrid.grid as TreeGridComponent;
        mriTreeGrid.updateCell(this.currentSelectedRow.rowIndex, 'category', arg.itemData.category);
        mriTreeGrid.setCellValue(this.currentSelectedRow.data.accountNumber, 'readAccount', arg.itemData.description);
        mriTreeGrid.updateCell(this.currentSelectedRow.rowIndex, 'readAccountDenominator', arg.itemData.denominator);
        mriTreeGrid.updateCell(this.currentSelectedRow.rowIndex, 'pnlGroup', arg.itemData.pnlGroup);
        this.onUpdateDenominatorByReadAccount(arg.itemData.denominator, arg.itemData.description);
    }

    onReadAcctDenominatorChange(arg?) {
        if (arg && arg.e) {
            const denominatorValue = arg.itemData ? arg.itemData.value : '';
            const readAccountValue = this.currentSelectedRow.data.readAccount;
            this.onUpdateDenominatorByReadAccount(denominatorValue, readAccountValue);
        }
    }

    onUpdateDenominatorByReadAccount(denominatorValue, readAccountValue) {
        const mriTreeGrid = this.mriGrid.grid as TreeGridComponent;
        (mriTreeGrid.dataSource as any).filter((i: any) => i.readAccount
            && i.readAccount === readAccountValue
            && i.readAccountDenominator !== denominatorValue).map(data => {
                const index = mriTreeGrid.grid.getRowIndexByPrimaryKey(data.accountNumber);
                if (index >= 0) {
                    mriTreeGrid.updateCell(index, 'readAccountDenominator', denominatorValue);
                }
            })
    }

    onChangeAccountType(e): void {
        const mriTreeGrid = this.mriGrid.grid as TreeGridComponent;
        if ((mriTreeGrid.getBatchChanges() as BatchChanges).changedRecords.length > 0) {
            mriTreeGrid.endEdit();
        } else {
            this.gridData = [];
            this.isUnmatched = !this.isUnmatched;
            this.unmatchedBtbTitle = this.isUnmatched ? 'Show All' : 'Show Unmatched only';
            this.gridData = this.onCheckAccountType(this.isUnmatched);
        }
    }

    onCheckAccountType(isUnmatched: boolean) {
        let readAccountData = [];
        if (isUnmatched) {
            readAccountData = this.allReadAccount.filter(i => !i.assignedAccount);
        } else {
            readAccountData = this.allReadAccount;
        }
        return readAccountData;
    }

    onGridLoad() {
        this.initData();
    }

    private initData() {
        this.utilityService.toggleLoading(true);
        this.getKarAccounts();
    }

    private initReferenceData() {
        this.loading = true;

        forkJoin([
            this.karAccountService.getMRIAccountData(),
            this.karAccountService.getPNLData(),
            this.karAccountService.getDenominatorData()
        ]).pipe(
            finalize(() => this.loading = false)
        ).subscribe((rs: [any[], any[], any[]]) => {
            const [mriAccount, pnlItems, denominators] = rs;

            this.mriAccount = mriAccount;
            this.pnlItems = pnlItems;
            this.readCodeItems = pnlItems.filter(i => i.readCode);
            this.denominatorItems = [...['ALL'], ...denominators];

            this.setupGrid();
        }, () => {
            this.mriAccount = [];
            this.pnlItems = [];
            this.readCodeItems = [];
            this.denominatorItems = [];
            this.utilityService.toastError('Fail to get MRI Account & PNL data');
        });
    }

    handleGridOutput(event: GridOutputEventArgs) {
        const { type, data } = event;

        switch (type) {
            case GridOutputEventType.SAVE:
                // validate
                if (!data) {
                    return;
                }

                const newData = data.data;
                const rowData = data.rowData;

                // check if there is no any new changes
                if (_.isEqual(newData, rowData)) {
                    return;
                }

                // save account

                
                break;
            case GridOutputEventType.REFRESH:
                this.addData = [];
                this.updateData = [];
                break;
            case GridOutputEventType.SAVE_BATCH:
                if (!data) {
                    return;
                }
                this.onSaveReadAccounts();
                break;
            default:
                break;
        }
    }

    onSaveReadAccounts() {
        if (this.updateData.length > 0) {
            this.onEditReadAccountRequest(this.updateData, this.updateData.map(a => a.accountNumber).toString());
        }
        if (this.addData.length > 0) {
            this.onCreateReadAccountRequest(this.addData, this.addData.map(a => a.accountNumber).toString());
        }
    }

    onActionBegin(args) {
    }

    onEditReadAccountRequest(submitAccounts, accountNumber) {
        this.utilityService.toggleLoading(true);
        this.karAccountService.editKarAccounts(submitAccounts).subscribe(() => {
            this.utilityService.toastSuccess('READ Account has been updated');
            this.getKarAccounts();
        }, (e) => {
            this.utilityService.toastError(`Fail to save READ Account ID: ${accountNumber}`);
            this.utilityService.toggleLoading(false);
        });
    }

    onCreateReadAccountRequest(submitAccounts, accountNumber) {
        this.utilityService.toggleLoading(true);
        this.karAccountService.addKarAccounts(submitAccounts).subscribe(() => {
            this.utilityService.toastSuccess('READ Account has been assigned');
            this.getKarAccounts();
        }, (e) => {
            this.utilityService.toastError(`Fail to save READ Account ID: ${accountNumber}`);
            this.utilityService.toggleLoading(false);
        });
    }

    // onAddReadAccountRequest(submitAccounts, accountNumber, updateAccounts: KarAccount[]) {
    //     this.KarAccountService.addKarAccounts(submitAccounts).subscribe(() => {
    //         this.utilityService.toastSuccess('READ Account has been saved');
    //         if (updateAccounts && updateAccounts.length > 0) {
    //             this.onEditReadAccountRequest(updateAccounts, updateAccounts.map(a => a.accountNumber));
    //         } else {
    //             this.getKarAccounts();
    //         }
    //     }, (e) => {
    //         this.utilityService.toastError(`Fail to save READ Account ID: ${accountNumber}`);
    //         this.getKarAccounts();
    //     });
    // }

    private getKarAccounts() {
        if (this.mriGrid) { this.mriGrid.showSpinner(); }
        this.karAccountService.getKarAccounts()
            .pipe(
                finalize(() => {
                    if (this.mriGrid) {
                        this.mriGrid.hideSpinner();
                        this.utilityService.toggleLoading(false);
                    }
                })
            )
            .subscribe(
                (rs) => {
                    this.gridData = [];
                    this.allReadAccount = rs.map(e => {
                        if (!e.readAccountDenominator) {
                            e.readAccountDenominator = ''
                        }
                        if (!e.kayneAccountDenominator) {
                            e.kayneAccountDenominator = ''
                        }
                        return e;
                    });
                    this.gridData = this.onCheckAccountType(this.isUnmatched);
                },
                () => {
                    this.utilityService.toastError('Fail to get READ Account data');
                    this.utilityService.toggleLoading(false);
                }
            );
    }
}
