import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import {
    ServerDownGuardService, AuthGuardService,
    AuthCallbackComponent, AccessDeniedComponent, NotFoundComponent
} from 'ka-ui-lib';
import { ReadServerDownComponent } from './components/read-server-down/read-server-down.component';

const routes: Routes = [
    {
        path: 'index.html',
        loadChildren: './pages/landing/landing.module#LandingModule',
        component: AuthCallbackComponent,
    },
    {
        path: '401',
        component: AccessDeniedComponent,
        data: { hideGlobalFilter: true }
    },
    {
        path: '404',
        component: NotFoundComponent,
        data: { hideGlobalFilter: true }
    },
    {
        path: 'unavailable',
        component: ReadServerDownComponent,
        data: { hideGlobalFilter: true }
    },
    {
        path: 'landing',
        loadChildren: './pages/landing/landing.module#LandingModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
        data: { title: 'Landing' }
    },
    {
        path: 'home',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
        data: { title: 'Home' }
    },
    {
        path: 'executive',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'data',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'platforms',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'opportunistic',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    },
    {
        path: 'core',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    },
    {
        path: 'user-assignment',
        loadChildren: './pages/user-assignment/user-assignment.module#UserAssignmentModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
        data: { title: 'User Assignment', hideGlobalFilter: true }
    },
    {
        path: 'debt',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'impact',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    },
    {
        path: 'teams',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'legal',
        loadChildren: './pages/legal/legal.module#LegalModule',
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'medical-office',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    },
    {
        path: 'seniors-housing',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    },
    {
        path: 'student-multi',
        loadChildren: './pages/home/home.module#HomeModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    },
    {
        path: 'investor-relations',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: 'FP&A',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'accounting',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'hr',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'valuations',
        loadChildren: './pages/valuation/valuation.module#ValuationModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
        data: { title: 'Valuations' }
    }, {
        path: 'financials',
        // component: UnderConstructionComponent,
        loadChildren: './pages/finance/finance.module#FinanceModule',
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'ESG',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'covenants',
        loadChildren: './pages/covenant/covenant.module#CovenantModule',
        canActivate: [AuthGuardService, ServerDownGuardService],
    }, {
        path: 'covenant-upload',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'covenant-pnl',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    }, {
        path: 'covenant-shock-scenarios',
        component: UnderConstructionComponent,
        canActivate: [AuthGuardService, ServerDownGuardService]
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
