import { KarCoreComponentModule } from 'ka-ui-lib';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SidebarModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NoSupportBrowserComponent } from './no-support-browser/no-support-browser.component';
import { ReadLeftNavbarComponent } from './read-left-navbar/read-left-navbar.component';
import { ReadHeaderComponent } from './read-header/read-header.component';
import { ReadFooterComponent } from './read-footer/read-footer.component';
import { NgModule } from '@angular/core';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { SvgLoaderComponent } from './svg-loader/svg-loader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { AutoFocusDirective } from './auto-focus-directive/auto-focus.directive';
import { SettingModalComponent } from './setting-modal/setting-modal.component';
import { CustomButtonComponent } from './button/button.component';
import { ReadServerDownComponent } from './read-server-down/read-server-down.component';
import { TabCachedComponent, TabGroupCachedComponent } from './tab-group-cached/tab-group-cached.component';
import { NumberOnlyDirective } from './only-number-directive/only-number.directive';
import { KarSharingModule } from '../shared/sharing/kar-sharing.module';

@NgModule({
    declarations: [
        NoSupportBrowserComponent,
        UnderConstructionComponent,
        SvgLoaderComponent,
        SelectDropdownComponent,
        AutoFocusDirective,
        SettingModalComponent,
        // custom layout components
        ReadFooterComponent,
        ReadLeftNavbarComponent,
        ReadHeaderComponent,
        CustomButtonComponent,
        ReadServerDownComponent,
        // custom components
        TabGroupCachedComponent,
        TabCachedComponent,
        NumberOnlyDirective
    ],
    exports: [
        NoSupportBrowserComponent,
        UnderConstructionComponent,
        SvgLoaderComponent,
        SelectDropdownComponent,
        AutoFocusDirective,
        // custom layout components
        ReadFooterComponent,
        ReadLeftNavbarComponent,
        ReadHeaderComponent,
        CustomButtonComponent,
        ReadServerDownComponent,
        // custom components
        TabGroupCachedComponent,
        TabCachedComponent,
        NumberOnlyDirective
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        SidebarModule,
        TreeViewModule,
        AngularSvgIconModule.forRoot(),
        KarCoreComponentModule
    ],
    providers: [
        NgbActiveModal
    ],
    entryComponents: [SettingModalComponent]
})
export class ComponentModule { }
