import { Injectable } from '@angular/core';
import { UserProfileService } from 'ka-ui-lib';

enum UserPermissionGroups {
    READ_DASHBOARD_EDIT = 'READ_DASHBOARD_EDIT'
}

@Injectable({
    providedIn: 'root',
})
export class UserPermissionService {
    constructor(private userProfileService: UserProfileService) { }

    // READ_DASHBOARD_EDIT
    hasEditGroup(): boolean {
        return this.userProfileService.hasUserGroup(UserPermissionGroups.READ_DASHBOARD_EDIT);
    }
}
