import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-tab-cached',
    templateUrl: './tab-cached.component.html'
})
export class TabCachedComponent {
    @Input() isConfirmBeforeLeave = false;
    @Input() childComponent: string;
    @Input() disabled = false;
    @Input() tabTitle: string;
    active = false;
    ready = false;

    @ContentChild(TemplateRef)
    templateRef: TemplateRef<any>;

    constructor() { }
}
