
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralRequestBody, MapDataRecord, PropertyData, GlobalFilter, RawDataModel, RawDataGridRowModel, FilterGeneralInfo } from '../models/common.model';
import { GlobalFilterResponse } from '../modules/main-filter/models/main-filter.model';
import * as _ from 'lodash';
import { DateService } from 'ka-ui-lib';
import { ReadNumeralService } from './read-numeral.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReadEndpointService {
    constructor(private http: HttpClient, private dateService: DateService, private numeralService: ReadNumeralService) { }

    globalPropertyFilterData = [];
    onGlobalFilterDataChanged = new BehaviorSubject({});
    onMapBehaviorChange = new BehaviorSubject({});
    private onGlobalFilterDataChanged$ = this.onGlobalFilterDataChanged.asObservable();

    onGlobalFilterChanged = new BehaviorSubject<GlobalFilter>(null);
    onGlobalFilterDataChangeReceived = new BehaviorSubject<any>(null);
    onGlobalFilterOptionsChangeReceived = new BehaviorSubject<any>(null);
    selectedDefaultProperty: string;
    onOrgEntityTypeChange = new BehaviorSubject<any>(null);
    onHomeGlobalFilterChanged = new BehaviorSubject<any>(null);
    onFilterDataChanged = new BehaviorSubject<any>(null);

    cachedRawDataGrid: { time: Date, result: { data: RawDataGridRowModel[], dateColumns: string[] } };
    cachedPnLGrid: { time: Date, result: any };

    private pnlDateColumns: string[];

    getPnLByGlobalFilter(data: GeneralRequestBody, isIgnoreGlobalFilterChange?: boolean): Observable<{ data: any[], dateColumns: string[] }> {
        const isApplyingGlobalFilter = !isIgnoreGlobalFilterChange ? this.isApplyingGlobalFilter(data.filterGeneralInfo) : true;

        if (!isApplyingGlobalFilter
            && !_.isNil(this.cachedPnLGrid)
            && !this.isCachedDataExpired(this.cachedPnLGrid.time)) {
            return Observable.of(this.cachedPnLGrid.result);
        }

        return this.http.post(`${environment.baseEndpointUrl}/financial/pnl`, data).pipe(
            map((res) => this.buildPnLGridData(res, false, isApplyingGlobalFilter))
        );
    }

    getRawData(data: GeneralRequestBody, isIgnoreGlobalFilterChange?: boolean): Observable<{ data: RawDataGridRowModel[], dateColumns: string[] }> {
        const isApplyingGlobalFilter = !isIgnoreGlobalFilterChange ? this.isApplyingGlobalFilter(data.filterGeneralInfo) : true;

        if (!isApplyingGlobalFilter
            && !_.isNil(this.cachedRawDataGrid)
            && !this.isCachedDataExpired(this.cachedRawDataGrid.time)) {
            return Observable.of(this.cachedRawDataGrid.result);
        }

        return this.http.post<RawDataModel[]>(`${environment.baseEndpointUrl}/financial/raw-data`, data).pipe(
            map((res) => this.buildRawDataGridData(res || [], isApplyingGlobalFilter))
        );
    }

    getGlobalFilterData(): Observable<any> {
        return this.onGlobalFilterDataChanged$;
    }

    setGlobalFilterData(lastValue: any) {
        return this.onGlobalFilterDataChanged.next(lastValue);
    }

    getFilters(): Observable<GlobalFilterResponse[]> {
        return this.http.get<GlobalFilterResponse[]>(`${environment.baseEndpointUrl}/home/filter-page`);
    }

    getHomeQuarters(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.baseEndpointUrl}/home/quarters`);
    }

    getOrgChartFilters(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.baseEndpointUrl}/organization/filter-data`);
    }

    getMapCoordinates(data: GeneralRequestBody): Observable<any> {
        return this.http.post(`${environment.baseEndpointUrl}/home/map-data`, data);
    }

    getValuationQuarters(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.baseEndpointUrl}/valuation-summary/quarters`);
    }

    getGeneralInfoByGlobalFilter(data: GeneralRequestBody): Observable<any> {
        return this.http.post(`${environment.baseEndpointUrl}/home/general-info`, data);
    }

    getProperties(data: GeneralRequestBody): Observable<PropertyData[]> {
        return this.http.post<PropertyData[]>(`${environment.baseEndpointUrl}/home/export-prop-data`, data);
    }

    getFinalizedQuarter(): Observable<string> {
        return this.http.get<string>(`${environment.baseEndpointUrl}/valuation-summary/finalized-quarter`);
    }

    setFinalizedQuarter(data: any) {
        return this.http.post(`${environment.baseEndpointUrl}/valuation-summary/finalized-quarter`, data);
    }

    sortRawDataGridWithGroupField(rs: RawDataGridRowModel[], sortFields: string[], sortDirections: (boolean | "asc" | "desc")[]): RawDataGridRowModel[] {
        const result = [];
        const orderedList = _.orderBy(rs.filter(i => !i.isSection), sortFields, sortDirections);

        let lastAcctName: string;
        let sectionIndex = 0;

        for (let i = 0; i < orderedList.length; i++) {
            const { accountName, isCollapsed } = orderedList[i];

            if (i === 0 || !_.eq(accountName, lastAcctName)) {
                sectionIndex++;
                result.push({
                    isSection: true,
                    sectionId: sectionIndex,
                    isCollapsed: false,
                    accountName
                });
                lastAcctName = accountName;
            }

            result.push({
                ...orderedList[i], sectionId: sectionIndex, isCollapsed: false,
            });
        }
        return result;
    }

    private isApplyingGlobalFilter(filterGeneralInfo?: FilterGeneralInfo): boolean {
        if (!filterGeneralInfo) return false;

        const { funds = [], sectors = [], properties = [], geographies = "[]" } = filterGeneralInfo;

        return !_.isEmpty(funds) ||
            !_.isEmpty(sectors) ||
            !_.isEmpty(properties) ||
            !_.isEmpty(JSON.parse(geographies));
    }

    private isCachedDataExpired(cachedTime: Date): boolean {
        const diff = Math.floor(Math.abs(this.dateService.diff(cachedTime, new Date())) / 60000);
        return diff >= environment.cachedRawDataPnLTime;
    }

    private buildRawDataGridData(rs: RawDataModel[] = [], isApplyingGlobalFilter: boolean = false): { data: RawDataGridRowModel[], dateColumns: string[] } {
        const dateColumns: { field: string, transactionDate: string, timestamp: number }[] = [];
        const dataResult = [];

        rs.forEach((data: RawDataModel, index: number) => {
            const sectionId = index;

            dataResult.push({
                accountName: data.accountRange,
                sectionId,
                isSection: true
            });

            (data.accountNumberGroup || []).forEach(accountGroup => {
                const { accountNumber } = accountGroup;

                const row: RawDataGridRowModel = {
                    readAccount: accountGroup.readAccount,
                    // accountName: accountNumber.startsWith('KA9') ? 'Occupancy Data' : data.accountRange,
                    accountName: data.accountRange,
                    accountNumber: accountGroup.accountNumber,
                    description: accountGroup.description,
                    sectionId,
                    isChild: true
                };

                (accountGroup.financeData || []).forEach(fd => {
                    const dateObj = this.dateService.initDate(fd.transactionDate);

                    const field = dateObj.format('MMM-YY');
                    const timestamp = dateObj.valueOf();

                    if (!dateColumns.some(d => d.field === field)) {
                        dateColumns.push({ field, transactionDate: fd.transactionDate, timestamp });
                    }
                    row[field] = fd.value;
                    row[`${field}_bookType`] = fd && fd.bookType;
                    row[`${field}_dataType`] = fd && fd.dataType;
                });

                dataResult.push(row);
            });
        });

        const result = {
            data: this.sortRawDataGridWithGroupField(dataResult, ['accountNumber'], ['asc']),
            dateColumns: _.sortBy(dateColumns, ['timestamp']).map(d => d.field)
        };

        // cached data if the global filter is not applied
        if (!isApplyingGlobalFilter) {
            this.cachedRawDataGrid = { time: new Date(), result };
        }

        return result;
    }

    buildPnLGridData(response, ignoreOrder = false, isApplyingGlobalFilter: boolean = false): { data: any[], dateColumns: string[] } {
        this.pnlDateColumns = [];

        const result = response.map((data: any) => {
            let parent = {
                category: data.category, childData: [], displayOrder: null, isParent: true, isStatus: false, isExpanded: false, passed: false, metric: false, isBackgroundEnable: false
            };

            // PASS & FAIL on HEADER Attribute
            if (data.headerAttributes) {
                parent.isStatus = true;
                parent.metric = data.headerAttributes.financeData[0] ? data.headerAttributes.financeData[0].metric : null;
                data.headerAttributes.financeData.map(dataAtt => {
                    if (dataAtt.transactionDate) {
                        parent.isBackgroundEnable = true;
                        parent[dataAtt.transactionDate] = { ...dataAtt, isStatus: true };
                    }
                });
            }

            // First child level
            if (data.financeAttributes && data.financeAttributes.length > 0) {
                parent.childData = this.convertPnLGridChildData(data, ignoreOrder);
            }

            // Second child level
            if (data.childData && data.childData.length > 0) {
                const secondChildData = data.childData.map(child => {
                    if (child.headerAttributes) {
                        child.isStatus = true;
                        child.subParent = true;
                        child.displayOrder = child.displayOrder;
                        child.metric = child.headerAttributes.financeData[0] ? child.headerAttributes.financeData[0].metric : null;
                        child.headerAttributes.financeData.map(dataAtt => {
                            if (dataAtt.transactionDate) {
                                child[dataAtt.transactionDate] = { ...dataAtt, isStatus: true };
                            }
                        });
                    }
                    if (child.financeAttributes && child.financeAttributes.length > 0) {
                        const subChildData = this.convertPnLGridChildData(child, ignoreOrder);
                        child.childData = subChildData;
                        return child;
                    }
                });
                const parentChildData = [...parent.childData, ...secondChildData];
                parent.childData = ignoreOrder ? parentChildData : _.sortBy(parentChildData, ['displayOrder']);
            }

            if (parent.childData.length === 1 && !parent.isStatus) {
                parent = { ...parent, ...parent.childData[0] };
                delete parent.childData;
            }

            parent.isExpanded = !!parent.metric;
            parent.displayOrder = data.displayOrder;

            return parent;
        });

        const pnlResult = { data: result, dateColumns: this.pnlDateColumns };

        // cached data if the global filter is not applied
        if (!isApplyingGlobalFilter) {
            this.cachedPnLGrid = { time: new Date(), result: pnlResult };
        }

        return pnlResult;
    }

    private convertPnLGridChildData(child, ignoreOrder = false) {
        const children = child.financeAttributes.map((firstChild) => {
            const data = {
                ...firstChild,
                category: firstChild.attribute,
                financeData: null,
                headerAttributes: null
            };

            if (firstChild.financeData && firstChild.financeData.length > 0) {
                firstChild.financeData.map(secondChild => {
                    if (secondChild.transactionDate) {
                        data[secondChild.transactionDate] = secondChild;
                        data.dataType = secondChild.dataType;
                        this.pnlDateColumns = this.pnlDateColumns.concat([secondChild.transactionDate].filter(i => this.pnlDateColumns.indexOf(i) < 0));
                    }
                });
            }

            return data;
        });

        return ignoreOrder ? children : _.sortBy(children, ['displayOrder']);
    }
}
