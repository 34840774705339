import { DataManager } from '@syncfusion/ej2-data';
import { Component, OnInit, OnDestroy, Input, ViewChild, SimpleChanges, OnChanges, ChangeDetectionStrategy, EventEmitter, Output, ElementRef, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { ToolbarItems, TreeGridComponent, PageSettingsModel, EditSettingsModel, SelectionSettingsModel, ColumnModel, TreeGridExcelExportProperties, TreeActionEventArgs } from '@syncfusion/ej2-angular-treegrid';
import { QueryCellInfoEventArgs, DialogEditEventArgs, RowDataBoundEventArgs, SaveEventArgs, ExcelQueryCellInfoEventArgs, DataSourceChangedEventArgs, InfiniteScrollSettingsModel, ExcelExport, ExcelExportProperties, RowSelectEventArgs, BatchChanges, EditEventArgs } from '@syncfusion/ej2-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { arg } from 'mathjs';

// tslint:disable-next-line: no-empty-interface
export interface KarTreeGridColumnOptions extends ColumnModel {
    templateRef?: TemplateRef<ElementRef>;
    isHidden?: boolean;
}

export interface KarTreeGridOptions {
    width?: any;
    height?: any;
    expandStateMapping?: string;
    allowSorting?: boolean;
    allowFiltering?: boolean;
    enableInfiniteScrolling?: boolean;
    infiniteScrollSettings?: InfiniteScrollSettingsModel;
    allowExcelExport?: boolean;
    allowResizing?: boolean;
    filterSettings?: { type?: 'Excel' };
    pageSettings?: PageSettingsModel;
    columns?: KarTreeGridColumnOptions[];
    rowHeight?: number;
    toolbarOptions?: (ToolbarItems | CustomGridToolbar.EXPAND_COLLAPSE | CustomGridToolbar.EXPAND_COLLAPSE_BY_LEVEL | object)[];
    enableCollapseAll?: boolean;
    selectionOptions?: SelectionSettingsModel;
    rowSelected?: (params: RowSelectEventArgs) => void;
    queryCellInfo?: (params: QueryCellInfoEventArgs) => void;
    rowDataBound?: (params: RowDataBoundEventArgs) => void;
    dataBound?: (params: any) => void;
    dataSourceChanged?: (params: DataSourceChangedEventArgs) => void;
    actionComplete?: (params: DialogEditEventArgs) => void;
    actionBegin?: (params: any) => void;
    excelQueryCellInfo?: (params: ExcelQueryCellInfoEventArgs) => void;
    // tslint:disable-next-line: ban-types
    disableEditingColumns?: string[];
    excelExportProperties?: ExcelExportProperties;
    sortSettings?: Object;
    childMapping?: string;
    treeColumnIndex?: number;
    editSettings?: EditSettingsModel;
    enableAltRows?: boolean;
    enableBorder?: boolean;
}

export enum GridOutputEventType {
    SAVE = 'save',
    SAVE_BATCH = 'save_batch',
    REFRESH = 'refresh'
}

export interface GridOutputEventArgs {
    type: GridOutputEventType;
    data?: any;
}

export enum CustomGridToolbar {
    EXCEL_EXPORT = 'ExcelExport',
    EXPAND_COLLAPSE = 'ExpandCollapse',
    EXPAND_COLLAPSE_BY_LEVEL = 'ExpandCollapseByLevel',
    CUSTOM_FITLER = 'CustomFilter',
    CUSTOM_UPDATE_BTN = 'CustomUpdateBtn',
}

const defaultTreeGridOptions = {
    allowSorting: true,
    allowFiltering: true,
    enableAltRows: true,
    enableBorder: true,
    allowResizing: true,
    enableInfiniteScrolling: true,
    treeColumnIndex: 0,
    rowHeight: 29,
    pageSettings: {
        pageSize: 50
    },
}

const collapseAllToolbarOptions = {
    tooltipText: 'Collapse All',
    prefixIcon: 'e-chevron-up-double',
    id: 'collapseAll',
};

const expandAllToolbarOptions = {
    tooltipText: 'Expand All',
    prefixIcon: 'e-chevron-down-double',
    id: 'expandAll',
};

const expandOneToolbarOptions = {
    tooltipText: 'Expand One',
    prefixIcon: 'e-chevron-down',
    id: 'expandOne',
};

const collapseOneToolbarOptions = {
    tooltipText: 'Collapse One',
    prefixIcon: 'e-chevron-up',
    id: 'collapseOne',
};

const expandOneByLevelToolbarOptions = {
    tooltipText: 'Expand One',
    prefixIcon: 'e-chevron-down',
    id: 'expandOneByLevel',
};

const collapseOneByLevelToolbarOptions = {
    tooltipText: 'Collapse One',
    prefixIcon: 'e-chevron-up',
    id: 'collapseOneByLevel',
};

const excelExportToolbarOptions = {
    tooltipText: 'Export Excel',
    prefixIcon: 'e-export-excel',
    id: 'excelExport',
};


@Component({
    selector: 'kar-ej2-treegrid',
    templateUrl: './kar-ej2-treegrid.component.html',
    styleUrls: [
        './kar-ej2-treegrid.component.css'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KarEj2TreeGridComponent implements OnInit, OnChanges, OnDestroy {
    @Input() data?: any[] | DataManager;
    @Input() options?: KarTreeGridOptions;

    @Output() gridOutput = new EventEmitter<GridOutputEventArgs>();
    @Output() gridLoad = new EventEmitter();

    gridColumns?: KarTreeGridColumnOptions[];

    @ViewChild('grid') grid: TreeGridComponent;
    isExpandedAll = true;

    get gridClass() {
        if (!this.options) return {};

        return {
            'enable-alt-rows': this.options.enableAltRows,
            'enable-border': this.options.enableBorder,
        }
    }

    constructor() {

    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !_.isNil(changes.options.currentValue)) {
            const options = this.options;

            if (options.enableInfiniteScrolling) {
                options.infiniteScrollSettings = {
                    initialBlocks: 5,
                    maxBlocks: 10,
                    enableCache: true,
                    ...(options.infiniteScrollSettings || {}),
                };
            }

            if (options.toolbarOptions) {
                // expand collapse toolbar
                const expandCollapseToolbarIndex = options.toolbarOptions.findIndex(t => t === CustomGridToolbar.EXPAND_COLLAPSE);
                if (expandCollapseToolbarIndex > -1) {
                    options.toolbarOptions.splice(expandCollapseToolbarIndex, 0, ...[
                        expandAllToolbarOptions,
                        expandOneToolbarOptions,
                        collapseAllToolbarOptions,
                        collapseOneToolbarOptions,
                    ]);
                    this.options.toolbarOptions = options.toolbarOptions.filter(t => t !== CustomGridToolbar.EXPAND_COLLAPSE);
                }

                const expandCollapseBykeyToolbarIndex = options.toolbarOptions.findIndex(t => t === CustomGridToolbar.EXPAND_COLLAPSE_BY_LEVEL);
                if (expandCollapseBykeyToolbarIndex > -1) {
                    options.toolbarOptions.splice(expandCollapseBykeyToolbarIndex, 0, ...[
                        expandAllToolbarOptions,
                        expandOneByLevelToolbarOptions,
                        collapseAllToolbarOptions,
                        collapseOneByLevelToolbarOptions,
                    ]);
                    this.options.toolbarOptions = options.toolbarOptions.filter(t => t !== CustomGridToolbar.EXPAND_COLLAPSE_BY_LEVEL);
                }

                // excel export toolbar
                const excelExportToolbarIndex = options.toolbarOptions.findIndex(t => t === CustomGridToolbar.EXCEL_EXPORT);
                if (excelExportToolbarIndex > -1) {
                    options.toolbarOptions.splice(excelExportToolbarIndex, 0, excelExportToolbarOptions);
                    this.options.toolbarOptions = options.toolbarOptions.filter(t => t !== CustomGridToolbar.EXCEL_EXPORT);
                }
            }



            this.options = {
                ...defaultTreeGridOptions,
                ...options
            }

            this.gridColumns = options.columns;
        }
    }


    onGridLoad(): void {
        // this.showSpinner();
        this.gridLoad.emit();
    }

    rowSelected(params: RowSelectEventArgs) {
        if (this.options && this.options.rowSelected) {
            this.options.rowSelected(params);
        }
    }

    queryCellInfo(params: QueryCellInfoEventArgs): void {
        if (this.options && this.options.queryCellInfo) {
            this.options.queryCellInfo(params);
        }
    }

    onExcelQueryCellInfo(params: ExcelQueryCellInfoEventArgs): void {
        if (this.options && this.options.queryCellInfo) {
            this.options.excelQueryCellInfo(params);
        }
    }

    showSpinner(): void {
        if (this.grid) {
            this.grid.showSpinner();
        }
    }

    hideSpinner(): void {
        if (this.grid) {
            this.grid.hideSpinner();
        }
    }

    onDataSourceChanged(params: DataSourceChangedEventArgs): void {
        if (this.options && this.options.dataSourceChanged) {
            this.options.dataSourceChanged(params);
        }
    }

    onRowDataBound(params: RowDataBoundEventArgs): void {
        if (this.options && this.options.rowDataBound) {
            this.options.rowDataBound(params);
        }
    }

    onDataBound(params: any): void {
        if (this.options && this.options.dataBound) {
            this.options.dataBound(params);
        }
    }

    private collapseAll(): void {
        (this.grid as TreeGridComponent).collapseAll();
    }

    private collapseOneByLevel(): void {
        const { rowKeysByLevel, currentLevel } = this.getCurrentExpandCollapseLevel();
        (this.grid as TreeGridComponent).collapseAtLevel(currentLevel - 1);

    }

    private expandOneByLevel(): void {
        const { rowKeysByLevel, currentLevel } = this.getCurrentExpandCollapseLevel();
        (this.grid as TreeGridComponent).expandAtLevel(currentLevel);
    }

    private expandAll(): void {
        (this.grid as TreeGridComponent).expandAll();
    }


    private collapseOne(): void {
        const { rowKeysByLevel, currentLevel } = this.getCurrentExpandCollapseLevel();
        rowKeysByLevel[currentLevel - 1].forEach(p => (this.grid as TreeGridComponent).collapseByKey(p));

    }

    private expandOne(): void {
        const { rowKeysByLevel, currentLevel } = this.getCurrentExpandCollapseLevel();
        rowKeysByLevel[currentLevel].forEach(p => (this.grid as TreeGridComponent).expandByKey(p));
    }

    onToolbarClick(args: ClickEventArgs): void {
        this.showSpinner();
        const itemId = args.item.id;
        setTimeout(() => {
            switch (itemId) {
                case 'excelExport':
                case 'ExcelExport':
                    this.grid.excelExport(this.options.excelExportProperties);
                    break;
                case 'clearFilter':
                    // clear current filter model
                    this.grid.clearFiltering();
                    break;
                case 'expandAll':
                    this.expandAll();
                    break;
                case 'collapseAll':
                    this.collapseAll();
                    break;
                case 'expandOne':
                    this.expandOne();
                    break;
                case 'collapseOne':
                    this.collapseOne();
                    break;
                case 'expandOneByLevel':
                    this.expandOneByLevel();
                    break;
                case 'collapseOneByLevel':
                    this.collapseOneByLevel();
                    break;
                default:
                    break;
            }
            this.hideSpinner();
        });
    }

    onActionComplete(args: TreeActionEventArgs): void {
        const requestType = args.requestType;
        switch (requestType) {
            case 'refresh':
                this.gridOutput.emit({ type: GridOutputEventType.REFRESH, data: true });
                break;
            case 'batchsave':
                this.gridOutput.emit({ type: GridOutputEventType.SAVE_BATCH, data: true });
                break;
            case 'save':
                this.gridOutput.emit({ type: GridOutputEventType.SAVE, data: args });
                break;
            default:
                break;
        }
    }

    refresh(): void {
        if (this.grid) {
            // this.grid.dataSource = this.gridData;
            this.grid.refresh();
        }
    }

    updateColumns(columns: KarTreeGridColumnOptions[]): void {
        this.grid.columns = columns;
        this.grid.refreshColumns();
    }

    private getCurrentExpandCollapseLevel() {
        const { parentData }: { parentData: any[] } = (this.grid as TreeGridComponent);
        let parentDataByLevel = [...parentData].filter(f => !_.isNil(f.childRecords)) as any[];
        let expandLevel = 0;
        let rowKeysByLevel = {
            0: [...parentDataByLevel.map(i => i.category)]
        };
        do {
            if (parentDataByLevel.filter(f => !!f.expanded).length > 0) {
                // there is at least one parent is expanding
                expandLevel += 1;
                let newParentDataByLevel = [];
                parentDataByLevel.forEach(p => {
                    p.childRecords.filter(pcr => !_.isNil(pcr.childRecords)).forEach(pcr => {
                        newParentDataByLevel.push(pcr);
                    });
                })
                parentDataByLevel = [...newParentDataByLevel];
                rowKeysByLevel[expandLevel] = [...parentDataByLevel.map(i => i.category)];
            } else {
                parentDataByLevel = [];
            }
        } while (!_.isEmpty(parentDataByLevel));

        return {
            currentLevel: expandLevel,
            rowKeysByLevel
        };
    }

    ngOnDestroy(): void { }
}
