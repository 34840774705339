import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-custom-button',
    template: `
    <button [class]="buttonClass" [disabled]="disabled" [attr.title]="title" (click)="onClick($event)">
        <ng-content></ng-content>
    </button>
    `,
    styleUrls: [
        './button.component.css'
    ]
})
export class CustomButtonComponent {
    @Input() color: 'primary' | 'success' | 'danger' | 'warning' | 'secondary' | 'default' = 'primary';
    @Input() title: string;
    @Input() size: 'sm' | 'lg' | 'xl';
    @Input() type: 'outline' | 'link';
    @Input() disabled = undefined;
    @Input() class: string;

    get buttonClass() {
        const classes = ['tb-button'];

        if (this.color) {
            classes.push(this.color);
        }

        if (this.size) {
            classes.push(this.size);
        }

        if (this.type) {
            classes.push(this.type);
        }

        if (this.class) {
            classes.push(this.class);
        }

        return classes.join(' ');
    }

    constructor() { }

    onClick(event) {
        event.preventDefault();
    }
}
